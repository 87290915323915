@font-face {
  font-family: 'tex_gyre_herosbold';
  src: url(/_next/static/fonts/texgyreheros-bold-webfont-586137731c82cdd247a1ca89de74c7c4.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'tex_gyre_herosbold_italic';
  src: url(/_next/static/fonts/texgyreheros-bolditalic-webfont-1f3cba21c291fd0c0138da397514ed89.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'tex_gyre_herositalic';
  src: url(/_next/static/fonts/texgyreheros-italic-webfont-5eeed332219f57cbb08263d138a59a21.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'tex_gyre_herosregular';
  src: url(/_next/static/fonts/texgyreheros-regular-webfont-4b507b0104c18eddfd15cbbffeab57a4.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'tex_gyre_heros_cnbold';
  src: url(/_next/static/fonts/texgyreheroscn-bold-webfont-a2b469da2eddf0f86796762aeed5eb17.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'tex_gyre_heros_cnbold_italic';
  src: url(/_next/static/fonts/texgyreheroscn-bolditalic-webfont-5fe66b2b0c2fb0f7f084b0c7b3c0982b.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'tex_gyre_heros_cnitalic';
  src: url(/_next/static/fonts/texgyreheroscn-italic-webfont-1d1c0739b5d0d904ce43b8d901f85f40.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'tex_gyre_heros_cnregular';
  src: url(/_next/static/fonts/texgyreheroscn-regular-webfont-d7bb2ae776c5a5cd83e46d1dec909ebf.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.progress-bar {
  position: fixed;
  top: 0;
  width: 100%; }

#nprogress {
  /* Make clicks pass-through */
  pointer-events: none;
  /* Fancy blur effect */
  /* Remove these to get rid of the spinner */ }
  #nprogress .bar {
    background: repeating-linear-gradient(45deg, #FF9A66, #FF9A66 4px, #ffffff 4px, #ffffff 8px);
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px; }
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #FF9A66, 0 0 5px #FF9A66;
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px); }
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px; }
  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: #FF9A66;
    border-left-color: #FF9A66;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    -ms-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite; }

.nprogress-custom-parent {
  overflow: hidden;
  position: relative; }
  .nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
    position: absolute; }

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

.page-header .title, .modal-wrapper .overlay .modal .modal-content .title {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.pagination-wrapper, .cues-wrapper .add-another {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.button a, .button.round, .button.round a, .modal-wrapper .overlay, .modal-wrapper .overlay .modal .modal-content .url-wrapper .orientation-radio, .modal-wrapper .overlay .modal .modal-content .url-wrapper .url .url-icon, .empty-wrapper.flex, .upload.multiple p .image-count, .upload .dropzone, .upload .image-feedback, .upload .image-thumbnails, .upload .image-thumbnail, .upload .image-thumbnail .destroy, .spinner-wrap.relativefull, .spinner-wrap.full, .spinner-wrap.large, .spinner-wrap.relative, .spinner-wrap.flex, .girl-spinner-wrapper, .cues-wrapper .cue-item-wrapper .cue-item-header .remove, .cues-wrapper .cue-item-wrapper .cue-sub-item-wrapper .cue-sub-item .remove, .main-area.flex, .back-to-top, .date-range span.dropdown, .avatar, .image-placeholder, .image-edit, .image-edit a {
  display: flex;
  justify-content: center;
  align-items: center; }

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: tex_gyre_herosregular; }
  *:before, *:after {
    box-sizing: border-box; }
  *:focus {
    outline: none; }

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; }

body {
  color: #231f20;
  font-size: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  display: block;
  padding: 0;
  margin: 0;
  background: white;
  overflow-x: hidden;
  min-height: 100vh; }

a {
  text-decoration: none;
  opacity: 1;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  color: inherit;
  background-color: inherit;
  outline: 0;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer; }
  a:hover {
    outline: 0;
    text-decoration: none;
    color: inherit;
    background-color: inherit; }
    a:hover img {
      opacity: 1;
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1); }
  a:visited, a:active, a:focus {
    color: inherit;
    outline: 0;
    text-decoration: none; }
  a.disabled {
    pointer-events: none; }
  a.simple {
    color: #F37737;
    text-transform: uppercase;
    letter-spacing: -1px;
    text-decoration: underline; }

img {
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s; }

.centered {
  margin: 24px auto;
  text-align: center; }

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px; }

.button {
  display: inline-block;
  border-radius: 2px;
  overflow: hidden; }
  .button a {
    padding: 10px 16px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.95rem;
    -webkit-transition: all ease 0.2s;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    transition: all ease 0.2s; }
    .button a:hover {
      background-color: #f73858 !important;
      color: #ffffff !important; }
    .button a.disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: not-allowed;
      pointer-events: none; }
  .button.round {
    border-radius: 50%; }
    .button.round a {
      border-radius: 50%;
      justify-content: center !important;
      width: 24px;
      height: 24px;
      padding: 0;
      box-shadow: none;
      text-transform: none;
      font-weight: normal;
      font-size: inherit; }
  .button.small > a {
    padding: 6px 12px;
    font-size: 0.8rem; }
  .button.bgred a {
    background-color: #f73858;
    color: #ffffff; }
  .button.bgorange a {
    background-color: #F37737;
    color: #ffffff; }
  .button.bggrey a {
    background-color: #B0B0B0;
    color: #ffffff; }
  .button.bgwhite a {
    background-color: #ffffff;
    color: #F37737; }
  .button.white a {
    color: #ffffff; }
  .button.black {
    color: #231f20; }
  .button.grey {
    color: #636363;
    border: 1px solid #636363; }

h1 {
  font-size: 2rem; }

h2 {
  font-size: 1.5rem; }

h3 {
  font-size: 1.3rem; }

h4 {
  font-size: 1.1rem; }

h5 {
  font-size: 0.8rem; }

p {
  color: #636363;
  font-size: 0.75rem; }
  p a {
    color: #F37737;
    text-decoration: underline; }

section {
  position: relative;
  width: 100%;
  margin: 0 auto; }
  section.modal-type {
    min-height: 100vh;
    background-color: #e9e9e9;
    background-repeat: repeat;
    background-position: center;
    background-size: 30%;
    padding: 64px 0; }
    section.modal-type .box {
      margin: 16px auto 0; }
      section.modal-type .box:first-child {
        margin: 0 auto; }

main {
  position: relative;
  background-repeat: repeat;
  background-position: center;
  min-height: 100%; }
  main.nopadding {
    padding: 0; }

.page-header {
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  align-items: center;
  margin-bottom: 36px; }
  .page-header h1, .page-header h2, .page-header h3, .page-header h4, .page-header h5, .page-header h6 {
    color: #231f20;
    text-transform: uppercase; }
  .page-header.icon h1, .page-header.icon h2, .page-header.icon h3, .page-header.icon h4, .page-header.icon h5, .page-header.icon h6 {
    display: grid;
    grid-template-columns: 36px auto;
    align-items: center; }
  .page-header.date-range {
    grid-template-columns: auto 200px; }
  .page-header.search {
    grid-template-columns: auto 300px; }
  .page-header.date-range.search {
    grid-template-columns: auto 300px 200px; }
  .page-header .title .button {
    margin-left: 24px; }

.bubble {
  position: absolute;
  padding: 16px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  z-index: 21;
  min-width: 200px;
  display: block; }
  .bubble.bottom {
    top: 100%; }
  .bubble.right {
    right: 0; }
  .bubble.left {
    left: 0; }
  .bubble.top {
    bottom: 100%;
    margin-bottom: 16px; }
  .bubble.vcenter {
    left: -150%; }
  .bubble.hcenter {
    top: -200%; }
  .bubble.large {
    width: 320px;
    max-height: 320px;
    overflow: auto; }
  .bubble .option a {
    color: #636363; }
  .bubble .confirmation .confirm-body {
    padding: 16px;
    text-align: center; }
  .bubble .confirmation .confirm-buttons {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px; }

.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000; }
  .modal-wrapper.wallet .overlay .modal {
    width: 800px;
    height: 90vh; }
    .modal-wrapper.wallet .overlay .modal .modal-content {
      height: 100%; }
  .modal-wrapper .overlay .modal {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    position: relative;
    z-index: 21;
    width: 400px;
    max-height: 95vh;
    overflow-y: auto; }
    .modal-wrapper .overlay .modal .modal-content {
      padding: 0 32px 32px; }
      .modal-wrapper .overlay .modal .modal-content .title {
        margin-bottom: 24px;
        text-transform: uppercase; }
      .modal-wrapper .overlay .modal .modal-content hr {
        height: 1px;
        width: 100%;
        background: #e9e9e9;
        margin: 16px 0; }
      .modal-wrapper .overlay .modal .modal-content .url-wrapper .orientation-radio {
        margin: 16px 0; }
      .modal-wrapper .overlay .modal .modal-content .url-wrapper form {
        width: 100%;
        padding: 0 16px; }
        .modal-wrapper .overlay .modal .modal-content .url-wrapper form label {
          text-align: left; }
      .modal-wrapper .overlay .modal .modal-content .url-wrapper .url {
        min-width: 280px;
        width: 100%;
        margin: 16px 0 0;
        position: relative; }
        .modal-wrapper .overlay .modal .modal-content .url-wrapper .url input {
          width: 100%;
          padding: 8px 16px 8px 32px;
          color: #9b9b9b; }
        .modal-wrapper .overlay .modal .modal-content .url-wrapper .url .url-icon {
          pointer-events: none;
          color: #9b9b9b;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          margin: 0 12px;
          z-index: 3; }

.confirmation .options {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 36px; }

.close-wrapper {
  position: relative;
  width: 100%;
  top: 0;
  text-align: right; }
  .close-wrapper .close {
    display: inline-block;
    color: #9b9b9b;
    padding: 16px;
    cursor: pointer; }
    .close-wrapper .close:hover {
      opacity: 0.7; }

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5); }

.box {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  position: relative; }
  .box .header {
    margin-bottom: 16px; }
    .box .header.bold {
      font-weight: 600; }
    .box .header a {
      color: #F37737;
      text-decoration: underline; }
  .box .title {
    padding: 16px;
    font-size: 0.9rem;
    border-bottom: 1px solid #F4F3F2; }
  .box .box-content {
    padding: 16px; }
    .box .box-content .hero {
      margin: 16px auto 32px;
      width: 300px; }
    .box .box-content .box-grid {
      display: grid; }

.load-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 4; }

.empty-wrapper {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .empty-wrapper.flex {
    flex-direction: column; }
  .empty-wrapper * {
    pointer-events: auto; }

.Toastify {
  font-size: 0.9rem;
  text-align: center; }

#recaptcha div {
  margin: 32px auto 16px;
  max-width: 100%; }

.upload.multiple .dropzone {
  border-radius: 5px;
  width: 100%;
  height: auto; }

.upload.multiple p {
  margin: 16px auto; }
  .upload.multiple p .image-count {
    font-size: 1.6rem;
    font-weight: bold;
    margin: 16px 0; }

.upload p {
  font-size: 0.8rem; }
  .upload p span {
    font-size: 0.75rem;
    color: #636363; }

.upload.type-avatar .dropzone {
  border-radius: 50%;
  width: 160px;
  height: 160px; }

.upload .dropzone {
  margin: 16px auto 32px;
  border: 3px dashed #9b9b9b;
  text-align: center;
  width: 100%;
  height: 200px;
  color: #636363;
  cursor: pointer;
  position: relative;
  align-items: center;
  background: #f7f7f7;
  overflow: hidden;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  .upload .dropzone img {
    border: none !important; }
  .upload .dropzone.dropaccept {
    background-color: #86D985;
    background-image: none !important; }
  .upload .dropzone.dropreject {
    background-color: #b91c1c;
    background-image: none !important; }

.upload .image-feedback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  align-items: center; }

.upload .image-thumbnails {
  margin-bottom: 32px; }
  .upload .image-thumbnails .image-thumbnail {
    border: 1px dashed #9b9b9b; }

.upload .image-thumbnail {
  position: relative;
  background-size: cover; }
  .upload .image-thumbnail img {
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .upload .image-thumbnail .destroy {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #d34836; }
    .upload .image-thumbnail .destroy i {
      opacity: 0;
      text-align: center;
      font-size: 5rem;
      color: #ffffff;
      margin: 0 auto; }
  .upload .image-thumbnail:hover .destroy {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5); }
    .upload .image-thumbnail:hover .destroy i {
      opacity: 1; }

iframe {
  width: 100%;
  height: 100%; }

.error {
  width: 100%;
  background: #d34836;
  color: #ffffff;
  padding: 8px;
  font-size: 0.8rem;
  text-align: center;
  margin: 8px 0; }
  .error .redirect {
    font-size: 0.75rem; }

.error-text {
  color: #d34836; }

.tip {
  color: #9b9b9b;
  font-size: 0.75rem;
  margin-bottom: 32px; }
  .tip a {
    color: #805e9e;
    text-decoration: underline; }
  .tip.with-icon {
    display: grid;
    grid-template-columns: 24px auto;
    text-align: left; }
  .tip .countdown {
    color: #231f20;
    font-weight: bold; }

.spaced {
  color: #9b9b9b;
  font-size: 1rem;
  margin: 8px 0; }
  .spaced.bold {
    font-weight: 600;
    color: #636363; }

.separator {
  text-align: center;
  color: #636363;
  padding: 16px 0;
  font-size: 1.1rem; }
  .separator span {
    color: #805e9e;
    font-weight: 900; }

.load-more {
  text-align: center;
  font-size: 0.8rem;
  margin: 24px auto; }
  .load-more .load-more-button {
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid #9b9b9b;
    background: #e9e9e9;
    display: inline-block; }

.hr {
  height: 1px;
  width: 100%;
  background: #e9e9e9;
  margin: 32px 0; }

svg ~ span, .button span ~ span {
  margin-left: 8px; }

.dot, .divider {
  margin: 0 4px; }

.empty {
  flex-grow: 2;
  position: relative; }

.faux-table {
  padding: 32px;
  border: 1px solid #231f20;
  display: grid;
  grid-template-columns: 35% 65%;
  font-weight: normal; }
  .faux-table * {
    margin: 4px 0; }
  .faux-table .heading {
    font-weight: bold;
    grid-column: 1 / span 2; }

.group:after {
  position: relative;
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.alignleft {
  text-align: left; }
  .alignleft p {
    text-align: inherit; }

.alignright {
  text-align: right; }
  .alignright p {
    text-align: inherit; }

.padding-top-16 {
  padding-top: 16px; }

.margin-top-16 {
  margin-top: 16px; }

.margin-bottom-16 {
  margin-bottom: 16px; }

.margin-bottom-24 {
  margin-bottom: 24px; }

.margin-left-8 {
  margin-left: 8px; }

@media (max-width: 1200px) {
  .container {
    width: 970px; }
  .box.large {
    width: 970px; } }

@media (max-width: 991px) {
  .container {
    width: 740px; }
  .box.large {
    width: 740px; } }

@media (max-width: 768px) {
  .container {
    width: 580px; }
    .container.main-menu, .container.search-container {
      width: 100%; }
  .box.large {
    width: 580px; }
  .faux-table {
    grid-template-columns: auto; }
    .faux-table * {
      display: block; }
    .faux-table .heading {
      grid-column: auto; }
    .faux-table .label {
      padding-top: 16px;
      font-weight: bold; } }

@media (max-width: 600px) {
  .container {
    width: 460px; }
  .box.mid, .box.large {
    width: 460px; }
  h1 {
    font-size: 1.6rem; }
  h2 {
    font-size: 1.3rem; }
  h3 {
    font-size: 1.1rem; } }

@media (max-width: 480px) {
  .container {
    width: 320px; }
  .box {
    width: 320px; }
    .box.small, .box.mid, .box.large {
      width: 320px; }
  .modal-wrapper .overlay .modal {
    width: 300px; }
  #recaptcha iframe {
    -webkit-transform: scale(0.78) !important;
    -ms-transform: scale(0.78) !important;
    transform: scale(0.78) !important;
    -webkit-transform-origin: 0 0 !important;
    -ms-transform-origin: 0 0 !important;
    transform-origin: 0 0 !important;
    width: auto; }
  #recaptcha .gc-reset {
    -webkit-transform: scale(0.78) !important;
    -ms-transform: scale(0.78) !important;
    transform: scale(0.78) !important;
    -webkit-transform-origin: 0 0 !important;
    -ms-transform-origin: 0 0 !important;
    transform-origin: 0 0 !important;
    width: auto; } }

@media (max-width: 360px) {
  .container {
    width: 300px; }
  .box {
    width: 300px; }
    .box.small, .box.mid, .box.large {
      width: 300px; }
    .box .box-content {
      padding: 32px 16px; } }

.container {
  width: 100%;
  margin: 0 auto;
  position: relative; }
  .container.list .box-grid {
    align-items: center; }
  .container.list .tip {
    margin-bottom: 8px; }
  .container.list .list-image {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    overflow: hidden;
    position: relative; }
    .container.list .list-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0; }
  .container.list .list-name {
    font-weight: bold;
    text-align: left; }
  .container.list .list-line {
    grid-column: 1 / -1;
    height: 1px;
    background: #e9e9e9; }
  .container.list .list-verified {
    color: #f73858;
    font-size: 0.8rem; }
    .container.list .list-verified .verified {
      color: #4bd349; }
  .container.list .list-grid {
    display: grid;
    grid-template-columns: 48px auto;
    padding: 8px;
    position: relative;
    align-items: center; }
  .container.list .toggler {
    margin-left: 0; }
  .container.list .fav {
    width: 64px;
    height: 64px;
    object-fit: cover;
    overflow: hidden;
    margin: 0 8px; }

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateX(125%);
    -ms-transform: translateX(125%);
    transform: translateX(125%); }
  100% {
    -webkit-transform: translateX(-250%);
    -ms-transform: translateX(-250%);
    transform: translateX(-250%); } }

@-moz-keyframes slide {
  0% {
    -webkit-transform: translateX(125%);
    -ms-transform: translateX(125%);
    transform: translateX(125%); }
  100% {
    -webkit-transform: translateX(-250%);
    -ms-transform: translateX(-250%);
    transform: translateX(-250%); } }

@keyframes slide {
  0% {
    -webkit-transform: translateX(125%);
    -ms-transform: translateX(125%);
    transform: translateX(125%); }
  100% {
    -webkit-transform: translateX(-250%);
    -ms-transform: translateX(-250%);
    transform: translateX(-250%); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes jump {
  0% {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
    opacity: 0; }
  10% {
    opacity: 1; }
  99% {
    opacity: 0;
    -webkit-transform: translateY(-16px) scale(1.5);
    -ms-transform: translateY(-16px) scale(1.5);
    transform: translateY(-16px) scale(1.5); }
  100% {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }

@-moz-keyframes jump {
  0% {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
    opacity: 0; }
  10% {
    opacity: 1; }
  99% {
    opacity: 0;
    -webkit-transform: translateY(-16px) scale(1.5);
    -ms-transform: translateY(-16px) scale(1.5);
    transform: translateY(-16px) scale(1.5); }
  100% {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }

@keyframes jump {
  0% {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
    opacity: 0; }
  10% {
    opacity: 1; }
  99% {
    opacity: 0;
    -webkit-transform: translateY(-16px) scale(1.5);
    -ms-transform: translateY(-16px) scale(1.5);
    transform: translateY(-16px) scale(1.5); }
  100% {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes flicker {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@-moz-keyframes flicker {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes flicker {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

.jumpUp {
  -webkit-animation: jump 0.8s ease;
  -ms-animation: jump 0.8s ease;
  animation: jump 0.8s ease; }

.fade {
  -webkit-transition: opacity 200ms linear;
  -moz-transition: opacity 200ms linear;
  -o-transition: opacity 200ms linear;
  transition: opacity 200ms linear; }
  .fade.fade-entering {
    opacity: 0.01; }
  .fade.fade-entered {
    opacity: 1; }
  .fade.fade-exiting {
    opacity: 0.01; }
  .fade.fade-exited {
    opacity: 0; }

.spinner-wrap {
  text-align: center; }
  .spinner-wrap.relativefull {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute; }
  .spinner-wrap.full {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0; }
  .spinner-wrap.large {
    padding: 128px; }
  .spinner-wrap.relative {
    padding: 10px 0; }
  .spinner-wrap.flex {
    height: 320px; }

.spinner {
  -webkit-animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  opacity: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block; }
  .spinner.orange-white {
    border: 3px solid #FF9A66;
    border-top: 3px solid #ffffff; }
  .spinner.white-orange {
    border: 3px solid #ffffff;
    border-top: 3px solid #F37737; }
  .spinner.white-offwhite {
    border: 3px solid #ffffff;
    border-top: 3px solid #F4F3F2; }
  .spinner.offwhite-orange {
    border: 3px solid #F4F3F2;
    border-top: 3px solid #F37737; }
  .spinner.grey-orange {
    border: 3px solid #F37737;
    border-top: 3px solid #e9e9e9; }

.girl-spinner-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #805e9e;
  flex-direction: column; }
  .girl-spinner-wrapper * {
    margin: 8px 0;
    color: #ffffff; }
  .girl-spinner-wrapper .girl-spinner {
    -webkit-animation: pulse 1s infinite;
    -ms-animation: pulse 1s infinite;
    animation: pulse 1s infinite;
    margin: 0 auto;
    max-width: 100px;
    width: 100px; }

.pagination-wrapper .pagination {
  display: grid;
  grid-template-columns: auto auto;
  width: 200px;
  column-gap: 24px; }

input[type=text], input[type=email], input[type=password], input[type=tel], input[type=number], textarea, select {
  background: #e9e9e9;
  padding: 8px;
  border-radius: 5px; }
  input[type=text].no-resize, input[type=email].no-resize, input[type=password].no-resize, input[type=tel].no-resize, input[type=number].no-resize, textarea.no-resize, select.no-resize {
    resize: none; }

label {
  color: #636363;
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 600;
  z-index: 1; }
  label a {
    color: #636363;
    text-decoration: underline; }

.invisible {
  position: relative; }
  .invisible label {
    font-size: 0.7rem;
    font-weight: 600;
    -webkit-transition: all ease-out 0.2s;
    -moz-transition: all ease-out 0.2s;
    -o-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
    pointer-events: none;
    text-transform: uppercase;
    text-align: left; }
  .invisible .price input:focus ~ .currency, .invisible .price input:valid ~ .currency {
    opacity: 1; }
  .invisible .price input ~ .currency {
    opacity: 0; }
  .invisible input:-webkit-autofill,
  .invisible input:-webkit-autofill:hover,
  .invisible input:-webkit-autofill:focus textarea:-webkit-autofill,
  .invisible textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  .invisible select:-webkit-autofill,
  .invisible select:-webkit-autofill:hover,
  .invisible select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-text-fill-color: #231f20; }
  .invisible input, .invisible textarea, .invisible select {
    background: transparent;
    border-radius: 0;
    font-size: 1.2rem;
    color: #231f20;
    font-weight: bold;
    padding: 0 8px 8px;
    -webkit-transition: all ease-out 0.3s;
    -moz-transition: all ease-out 0.3s;
    -o-transition: all ease-out 0.3s;
    transition: all ease-out 0.3s; }
  .invisible textarea {
    overflow: hidden;
    height: auto; }
  .invisible .underline {
    display: block;
    background: #F37737;
    height: 2px;
    width: 0;
    margin: 0 auto;
    -webkit-transition: all ease-out 0.2s;
    -moz-transition: all ease-out 0.2s;
    -o-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s; }
  .invisible input:focus ~ .underline, .invisible textarea:focus ~ .underline {
    width: 100%; }
  .invisible input:disabled, .invisible input:disabled ~ label {
    color: #B0B0B0; }

.line {
  position: relative; }
  .line .price input:focus ~ .currency, .line .price input:valid ~ .currency {
    opacity: 1; }
  .line .price input ~ .currency {
    opacity: 0; }
  .line input, .line textarea, .line select {
    background: transparent;
    border-radius: 0;
    border-bottom: 1px solid #B0B0B0;
    -webkit-transition: all ease-out 0.3s;
    -moz-transition: all ease-out 0.3s;
    -o-transition: all ease-out 0.3s;
    transition: all ease-out 0.3s; }
  .line textarea {
    overflow: hidden;
    height: auto; }
  .line input:focus, .line input:valid, .line textarea:focus, .line textarea:valid, .line select:valid {
    border-bottom: 1px solid #F37737; }
  .line input:disabled, .line input:disabled ~ label {
    color: #B0B0B0; }
  .line input:focus ~ label, .line input:valid ~ label, .line textarea:focus ~ label, .line textarea:valid ~ label, .line select:valid ~ label {
    top: -16px;
    left: 0;
    font-size: 0.75rem; }
  .line input ~ label, .line textarea ~ label, .line select ~ label {
    font-size: 1rem;
    font-weight: 600;
    -webkit-transition: all ease-out 0.2s;
    -moz-transition: all ease-out 0.2s;
    -o-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
    position: absolute;
    pointer-events: none;
    top: 8px;
    left: 8px; }
  .line .length-counter {
    float: right;
    font-size: 0.6rem;
    color: #9b9b9b;
    margin-bottom: 4px; }

form.edit {
  display: grid;
  grid-template-columns: auto 300px;
  column-gap: 24px; }

form p a {
  margin-left: 8px; }

form .button {
  display: block; }

form .input-group {
  display: grid;
  grid-template-columns: 100%;
  margin-bottom: 16px; }
  form .input-group .tip {
    margin-bottom: 0px; }
  form .input-group.invisible {
    margin-bottom: 8px;
    margin-top: 16px; }
  form .input-group.line {
    margin-bottom: 8px;
    margin-top: 32px; }
  form .input-group.radio {
    margin: 0;
    width: 50%;
    float: left; }
    form .input-group.radio .field {
      display: grid;
      grid-template-columns: 18px auto; }
      form .input-group.radio .field input {
        outline: 0;
        margin-right: 8px;
        visibility: hidden; }
        form .input-group.radio .field input:checked + label:before {
          background-color: #805e9e;
          border: none; }
        form .input-group.radio .field input:checked + label:after {
          top: 4.4px;
          left: 4px;
          width: 10px;
          height: 6px;
          border: 2px solid #ffffff;
          border-top-style: none;
          border-right-style: none;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg); }
        form .input-group.radio .field input:disabled + label:before {
          border-color: #9b9b9b; }
        form .input-group.radio .field input:disabled:checked + label:before {
          background-color: #9b9b9b; }
      form .input-group.radio .field label {
        cursor: pointer;
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0;
        padding-left: 8px; }
        form .input-group.radio .field label:before, form .input-group.radio .field label:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0; }
        form .input-group.radio .field label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          background: #ffffff;
          border: 2px solid #636363;
          border-radius: 2px;
          cursor: pointer;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s; }
  form .input-group.checkbox {
    display: block;
    margin: 32px 0; }
    form .input-group.checkbox .field {
      display: grid;
      grid-template-columns: 18px auto; }
      form .input-group.checkbox .field input {
        outline: 0;
        margin-right: 8px;
        visibility: hidden; }
        form .input-group.checkbox .field input:checked + label:before {
          background-color: #805e9e;
          border: none; }
        form .input-group.checkbox .field input:checked + label:after {
          top: 4.4px;
          left: 4px;
          width: 10px;
          height: 6px;
          border: 2px solid #ffffff;
          border-top-style: none;
          border-right-style: none;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg); }
        form .input-group.checkbox .field input:disabled + label:before {
          border-color: #9b9b9b; }
        form .input-group.checkbox .field input:disabled:checked + label:before {
          background-color: #9b9b9b; }
      form .input-group.checkbox .field label {
        cursor: pointer;
        font-weight: 400;
        margin-bottom: 0;
        padding-left: 16px; }
        form .input-group.checkbox .field label:before, form .input-group.checkbox .field label:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0; }
        form .input-group.checkbox .field label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          background: #ffffff;
          border: 2px solid #636363;
          border-radius: 2px;
          cursor: pointer;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s; }
  form .input-group.has-forgot {
    grid-template-columns: auto auto; }
    form .input-group.has-forgot .forgot {
      color: #805e9e;
      font-size: 0.8rem;
      margin-bottom: 8px;
      text-decoration: underline;
      place-self: center end; }
    form .input-group.has-forgot .field {
      grid-column: span 2; }
  form .input-group .field {
    position: relative;
    display: block; }
    form .input-group .field .show-password {
      position: absolute;
      right: 8px;
      color: #231f20;
      top: 4px; }
    form .input-group .field .length-counter {
      color: #9b9b9b;
      font-size: 0.7rem;
      float: right; }
    form .input-group .field.price .currency {
      -webkit-transition: all ease-out 0.2s;
      -moz-transition: all ease-out 0.2s;
      -o-transition: all ease-out 0.2s;
      transition: all ease-out 0.2s;
      pointer-events: none;
      position: absolute;
      top: 0;
      margin: 6px 8px;
      font-size: 90%;
      color: #636363; }
    form .input-group .field.price input {
      padding: 8px 8px 8px 48px; }
  form .input-group label, form .input-group input, form .input-group textarea, form .input-group select {
    width: 100%;
    display: block; }
  form .input-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    form .input-group select::-ms-expand {
      display: none; }
    form .input-group select option {
      color: #636363; }
      form .input-group select option:checked {
        background-color: #9b9b9b;
        color: #ffffff; }
  form .input-group .dropdown-icon {
    pointer-events: none;
    color: #9b9b9b;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 12px; }

.verification {
  max-width: 100% !important;
  margin-bottom: 32px; }
  .verification .characters .character {
    border-radius: 5px;
    background: #e9e9e9;
    color: #636363;
    border: none; }
    .verification .characters .character.selected {
      color: #231f20; }
    .verification .characters .character.inactive {
      color: #9b9b9b; }

.toggler-wrapper {
  width: 100%;
  position: relative; }
  .toggler-wrapper label {
    margin: 0; }
  .toggler-wrapper .toggler {
    align-self: flex-end;
    margin-left: auto;
    width: 48px;
    height: 28px;
    border-radius: 9999px;
    background: #d34836;
    float: right;
    position: relative;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s; }
    .toggler-wrapper .toggler .ball {
      margin: 2px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #ffffff;
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s; }
    .toggler-wrapper .toggler.active {
      background: #4bd349; }
      .toggler-wrapper .toggler.active .ball {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px); }

.books-wrapper .book-wrapper {
  border-bottom: 1px solid #e9e9e9; }
  .books-wrapper .book-wrapper .book-title {
    display: grid;
    grid-template-columns: 64px auto;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px; }
    .books-wrapper .book-wrapper .book-title .counter {
      border-radius: 50%;
      background: #F37737;
      color: #ffffff;
      width: 24px;
      height: 24px;
      margin: 8px 0;
      text-align: center; }
  .books-wrapper .book-wrapper .book {
    padding: 0 16px; }

.cues-wrapper .cue-item-wrapper {
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px; }
  .cues-wrapper .cue-item-wrapper .cue-item-header {
    display: grid;
    padding: 10px;
    grid-template-columns: 64px auto 64px;
    border-bottom: 1px solid #e9e9e9; }
    .cues-wrapper .cue-item-wrapper .cue-item-header .dropdown-button {
      color: #F37737;
      margin: 0 auto;
      padding-top: 8px; }
    .cues-wrapper .cue-item-wrapper .cue-item-header .remove a {
      border-radius: 50%;
      background: #f73858;
      color: #ffffff;
      width: 24px;
      height: 24px;
      margin: 8px 0;
      text-align: center; }
  .cues-wrapper .cue-item-wrapper .cue-sub-item-wrapper {
    max-height: 0px;
    background: #f73858;
    overflow: hidden;
    -webkit-transition: all ease-out 0.3s;
    -moz-transition: all ease-out 0.3s;
    -o-transition: all ease-out 0.3s;
    transition: all ease-out 0.3s;
    padding: 0px 20px;
    background: #ffffff; }
    .cues-wrapper .cue-item-wrapper .cue-sub-item-wrapper.active {
      max-height: 9999px; }
    .cues-wrapper .cue-item-wrapper .cue-sub-item-wrapper .add-another {
      display: block;
      padding: 0px 0px 20px 0px; }
      .cues-wrapper .cue-item-wrapper .cue-sub-item-wrapper .add-another .button {
        border-radius: 0px 0px 10px 10px; }
    .cues-wrapper .cue-item-wrapper .cue-sub-item-wrapper .cue-sub-item {
      display: grid;
      grid-template-columns: 64px auto 64px;
      padding: 16px;
      border-bottom: 0.5px solid #F37737; }
      .cues-wrapper .cue-item-wrapper .cue-sub-item-wrapper .cue-sub-item .counter {
        border-radius: 50%;
        background: #F37737;
        color: #ffffff;
        width: 24px;
        height: 24px;
        margin: auto;
        text-align: center; }
      .cues-wrapper .cue-item-wrapper .cue-sub-item-wrapper .cue-sub-item .remove a {
        border-radius: 50%;
        background: #f73858;
        color: #ffffff;
        width: 24px;
        height: 24px;
        margin: 8px 0;
        text-align: center; }

.cues-wrapper .add-another {
  padding: 10px; }

#help-and-support .main-area {
  background-color: #FF9A66; }

#help-and-support h2 {
  color: white; }

#help-and-support h3, #help-and-support h4, #help-and-support h5 {
  color: #F37737; }

#help-and-support select {
  width: 50%;
  height: 30px;
  color: #F37737; }
  #help-and-support select span.dropdown-icon {
    color: #F37737; }

#help-and-support textarea {
  width: 100%;
  height: 200px; }

#help-and-support .full-width-text {
  width: 100%; }

#help-and-support .cards {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border: 1px solid #FF9A66;
  color: #FF9A66;
  border-radius: 5px;
  box-shadow: 2px 2px 5px; }
  #help-and-support .cards:hover {
    background-color: #FF9A66;
    color: white; }

#help-and-support .cards-description {
  border-bottom: 1px solid #FF9A66;
  padding: 10px 0px; }

#help-and-support .back-button {
  margin-top: 10px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #636363;
  color: #636363;
  padding: 10px; }

.main-wrapper {
  width: 100%;
  min-height: 100vh;
  display: grid; }
  .main-wrapper.split {
    grid-template-columns: 200px auto; }

header {
  width: 100%;
  height: 100vh;
  position: relative; }
  header .menu-wrapper {
    background: #F37737;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 200px; }
    header .menu-wrapper .logo {
      background: #F37737;
      margin: 0 auto;
      padding: 18px; }
    header .menu-wrapper .menu-item {
      color: #ffffff;
      width: 100%; }
      header .menu-wrapper .menu-item .icon {
        margin: 0 auto; }
      header .menu-wrapper .menu-item a {
        display: grid;
        grid-template-columns: 48px auto;
        padding: 8px;
        position: relative;
        -webkit-transition: all ease-out 0.2s;
        -moz-transition: all ease-out 0.2s;
        -o-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s; }
        header .menu-wrapper .menu-item a:hover {
          background: #FF9A66; }
        header .menu-wrapper .menu-item a.selected {
          background: #FF9A66; }
          header .menu-wrapper .menu-item a.selected:before {
            content: '';
            border-left: 4px solid #ffffff;
            position: absolute;
            height: 100%; }
          header .menu-wrapper .menu-item a.selected:after {
            content: '';
            position: absolute;
            right: 0;
            height: 100%;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-right: 20px solid #ffffff; }
    header .menu-wrapper .bottom {
      margin-top: auto;
      padding-bottom: 36px; }

.main-area {
  padding: 24px;
  background: #F4F3F2;
  position: relative; }
  .main-area.flex {
    flex-direction: column; }

.back-to-top {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #ffffff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  position: fixed;
  bottom: 92px;
  right: 32px;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  position: fixed;
  z-index: 19; }
  .back-to-top.active {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  .back-to-top:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }

.date-range {
  position: relative;
  display: grid;
  grid-template-columns: 50px auto auto;
  align-items: center; }
  .date-range label {
    margin: 0 8px 0 0;
    color: #231f20;
    font-size: 0.9rem; }
  .date-range select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px 0 0 5px;
    grid-column: 2 / span 2; }
    .date-range select:hover {
      cursor: pointer; }
  .date-range span.dropdown {
    position: absolute;
    right: 0;
    width: 36px;
    height: 100%;
    border-radius: 0 5px 5px 0;
    background: #F37737;
    color: #ffffff;
    pointer-events: none; }

.avatar {
  position: relative;
  background: #F37737;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid rgba(255, 255, 255, 0.5); }
  .avatar.small {
    width: 16px;
    height: 16px; }
  .avatar.medium {
    width: 32px;
    height: 32px; }
  .avatar.large {
    width: 64px;
    height: 64px; }
  .avatar.xlarge {
    width: 96px;
    height: 96px; }
  .avatar.xxlarge {
    width: 128px;
    height: 128px; }
  .avatar.centered {
    margin: 0 auto; }
  .avatar.error {
    margin: 0; }
  .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%; }
  .avatar .avatar-child {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all ease-out 0.2s;
    -moz-transition: all ease-out 0.2s;
    -o-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s; }
  .avatar:hover .avatar-child {
    opacity: 1;
    pointer-events: auto; }

.image-placeholder {
  position: relative;
  background: #e9e9e9;
  border-radius: 5px;
  overflow: hidden;
  border: 3px dashed #B0B0B0; }
  .image-placeholder.full {
    width: 100%;
    height: 150px;
    position: relative; }
  .image-placeholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; }
  .image-placeholder .image-placeholder-child {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all ease-out 0.2s;
    -moz-transition: all ease-out 0.2s;
    -o-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s; }
  .image-placeholder:hover .image-placeholder {
    opacity: 1;
    pointer-events: auto; }

.image-edit {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%; }
  .image-edit.plain {
    background: transparent; }
  .image-edit.white {
    color: #ffffff; }
  .image-edit.gray {
    color: #B0B0B0; }
  .image-edit a {
    width: 100%;
    height: 100%;
    font-size: 0.7rem;
    color: inherit;
    background-color: transparent; }

.progress-wrapper {
  margin: 0 auto;
  position: relative;
  text-align: center;
  background: #ffffff;
  color: #636363;
  padding: 16px; }
  .progress-wrapper .progress-bar {
    width: 100%;
    margin: 16px 0;
    position: relative;
    height: 6px;
    background: #F4F3F2; }
    .progress-wrapper .progress-bar .progress {
      -webkit-transition: all ease-out 0.2s;
      -moz-transition: all ease-out 0.2s;
      -o-transition: all ease-out 0.2s;
      transition: all ease-out 0.2s;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #F37737; }
